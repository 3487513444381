import React, { useEffect, useState } from "react";
import {
  collection,
  getDoc,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ServiceAdd = () => {
  const [formData, setFormData] = React.useState({
    department: "",
    serviceManager: "",
    departmentPhone: "",
    departmentEmail: "",
  });

  const handleServiceAdd = async (e) => {
    e.preventDefault();
    try {
      const res = await addDoc(collection(db, "departments"), {
        department: formData.department,
        serviceManager: formData.serviceManager,
        departmentPhone: formData.departmentPhone,
        departmentEmail: formData.departmentEmail,
        date: new Date().toLocaleString() + "",
      });
      toast.success("Προστέθηκε νέα υπηρεσία", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
      });
      setFormData({
        department: "",
        serviceManager: "",
        departmentPhone: "",
        departmentEmail: "",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form onSubmit={handleServiceAdd}>
      <h4 className="mb-4 text-lg font-semibold text-gray-600 dark:text-gray-300">
        Προσθήκη υπηρεσίας
      </h4>
      <label className="block text-sm">
        <span className="text-gray-700 dark:text-gray-400">Όνομα φορέα</span>
        <input
          id="dname"
          type="text"
          name="dname"
          value={formData.department}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, department: e.target.value }))
          }
          className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray form-input"
        />
      </label>
      <label className="block mt-4 text-sm">
        <span className="text-gray-700 dark:text-gray-400">
          Ονοματεπώνυμο υπεύθυνου
        </span>
        <input
          id="fullname"
          type="text"
          name="fullname"
          value={formData.serviceManager}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, serviceManager: e.target.value }))
          }
          className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray form-input"
        />
      </label>

      <label className="block mt-4 text-sm">
        <span className="text-gray-700 dark:text-gray-400">Τηλέφωνο</span>
        <input
          id="phone"
          type="phone"
          name="phone"
          value={formData.departmentPhone}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              departmentPhone: e.target.value,
            }))
          }
          className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray form-input"
        />
      </label>
      <label className="block mt-4 text-sm">
        <span className="text-gray-700 dark:text-gray-400">Email</span>
        <input
          id="email"
          type="email"
          name="email"
          value={formData.departmentEmail}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              departmentEmail: e.target.value,
            }))
          }
          className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray form-input"
        />
      </label>
      <button
        type="submit"
        className="mt-4 px-3 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
      >
        Προσθήκη
      </button>
    </form>
  );
};

export default ServiceAdd;
