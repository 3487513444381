import React, { useEffect, useState } from "react";
import { onSnapshot, collection } from "firebase/firestore";
import { db } from "../config/firebase";
import ServiceListSingle from "./ServiceListSingle";

const ServicesList = () => {
  const [services, setServices] = React.useState([]);

  useEffect(
    () =>
      onSnapshot(collection(db, "departments"), (snapshot) =>
        setServices(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );

  return (
    <div className="w-full overflow-hidden  shadow-xs">
      <table className="w-full whitespace-no-wrap">
        <thead>
          <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
            <th className="px-4 py-3">Δήμος</th>
            <th className="px-4 py-3">Τηλέφωνο / Email</th>
            <th className="px-4 py-3">Υπεύθυνος </th>
            <th className="px-4 py-3">Επεξεργασία</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
          {services.map((key) => (
            <ServiceListSingle
              key={key.id}
              id={key.id}
              department={key.department}
              serviceManager={key.serviceManager}
              departmentPhone={key.departmentPhone}
              departmentEmail={key.departmentEmail}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ServicesList;
