import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import {
  setDoc,
  collection,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const handleDecline = async (id) => {
  const docRef = doc(db, "requests", id);
  await deleteDoc(docRef);
  toast.success("Διαγράφηκε", {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000,
    hideProgressBar: true,
  });
};

const RequestsListSingle = ({
  id,
  email,
  fullname,
  phone,
  date,
  title,
  address,
  description,
  status,
  photoURL,
  lat,
  lng,
  department,
}) => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const hex = require('string-hex');
  return (
    <tr className="text-gray-700 dark:text-gray-400">
      <td className="px-4 py-3 text-sm">{hex(id).slice(0, 8)}</td>
      <td className="px-4 py-3 text-sm">{date}</td>
      <td className="px-4 py-3">
        <div className="flex items-center text-sm">
          <div>
            <p className="font-semibold mb-2">{title}</p>
            <p className="text-xs text-gray-600 dark:text-gray-400">
              {address}
            </p>
          </div>
        </div>
      </td>
      <td className="px-4 py-3 text-sm">
        {status === "Νέο αίτημα" ? "-" : department}
      </td>
      <td className="px-4 py-3 text-xs">
        <span
          className={`px-2 py-1 font-semibold  dark:bg-green-700 dark:text-green-100 leading-tight  
                    ${
                      status === "Γνωστοποιήθηκε"
                        ? "text-green-700 bg-green-100"
                        : status === "Καταχωρήθηκε"
                        ? "text-orange-700 bg-orange-100"
                        : status === "Νέο αίτημα"
                        ? "text-red-700 bg-red-100"
                        : status === "Επιλύθηκε"
                        ? "text-gray-700 bg-gray-100"
                        : status === "Σε εξέλιξη"
                        ? "text-gray-700 bg-gray-100"
                        : ""
                    }`}
        >
          {status}
        </span>
      </td>
      <td className="px-4 py-3 text-xs">
        <div className="flex items-center space-x-4 text-sm">
          <Link
            to="/single-request"
            state={{
              id: id,
              email: email,
              fullname: fullname,
              phone: phone,
              date: date,
              title: title,
              address: address,
              description: description,
              photoURL: photoURL,
              status: status,
              lat: lat,
              lng: lng,
            }}
            className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
            aria-label="Edit"
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
            </svg>
          </Link>
          <button
            onClick={onOpenModal}
            className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
            aria-label="Delete"
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <Modal open={open} onClose={onCloseModal} center classNames="">
            <h2 className="mb-8">
              Με την συγκεκριμένη ενέργεια το αίτημα θα διαγραφεί μόνιμα.
            </h2>
            <button
              onClick={() => handleDecline(id)}
              className="bg-red mr-5 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent  active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
            >
              ΜΟΝΙΜΗ ΔΙΑΓΡΑΦΗ
            </button>
            <button
              onClick={onCloseModal}
              className="bg-green px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent  active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
            >
              ΔΙΑΤΗΡΗΣΗ ΑΙΤΗΜΑΤΟΣ
            </button>
          </Modal>
        </div>
      </td>
    </tr>
  );
};

export default RequestsListSingle;
