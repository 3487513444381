import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCLkruWr5-sPlPvkt4N23qKQtbWvq6B9Ts",
  authDomain: "enametonpoliti-4c958.firebaseapp.com",
  projectId: "enametonpoliti-4c958",
  storageBucket: "enametonpoliti-4c958.appspot.com",
  messagingSenderId: "933891465576",
  appId: "1:933891465576:web:a1e6466fa09ad00251f6dd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);
