import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import {
  collection,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  where,
  onSnapshot,
  query,
  addDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { useNavigate } from "react-router";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./../components/Header";
import Sidebar from "./../components/Sidebar";
import Map from "./../components/Map";
import RequestSend from "./../components/RequestSend";

import placeholder from "../assets/img/placeholder.png";

const SingleRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, status, lat, lng } = location.state;

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const hex = require('string-hex');

  const [state, setState] = React.useState({
    value: location.state.status,
  });

  const [requests, setRequests] = React.useState([{}]);
  useEffect(() => {
    const q = query(
      collection(db, "requests"),
      where("id", "==", location.state.id)
    );
    onSnapshot(q, collection(db, "requests"), (snapshot) =>
      setRequests(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );
  }, [state]);

  const [statusSend, setStatusSend] = useState("");

  useEffect(
    () =>
      onSnapshot(collection(db, "requests"), (snapshot) =>
        setStatusSend(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    [state]
  );

  const handleChange = (e) => {
    setState({ value: e.target.value });
  };

  const handleAccept = async (id) => {
    const docRef = doc(db, "requests", id);
    await updateDoc(docRef, {
      status: "Καταχωρήθηκε",
      department: "Εσωτερικό",
      timestamp: serverTimestamp(),
    });
    toast.success("Το νεο  αίτημά καταχωρήθηκε με επιτυχία", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
      hideProgressBar: true,
    });
    setState({ value: "Καταχωρήθηκε" });

    const res = await addDoc(collection(db, "mail"), {
      to: requests[0].email,
      message: {
        subject: "enametonpoliti.gr",
        text: "",
        html: "<p>Το enametonpoliti.gr αποδέχθηκε το αίτημα σας. Θα ενημερωθείτε εκ νέου με email για την πορεία του.</p>" +
        "<img src='https://firebasestorage.googleapis.com/v0/b/enametonpoliti-4c958.appspot.com/o/email_banner.png?alt=media&token=1ed2215c-01b8-4afc-9686-13f8e2e9be4d' alt='logo' />",
      },
    });
  };

  const handleEditStatus = async (e) => {
    e.preventDefault();
    const docRef = doc(db, "requests", id);
    await updateDoc(docRef, {
      status: state.value,
    });
    toast.success("Η κατάσταση άλλαξε ", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
      hideProgressBar: true,
    });

    if (state.value === "Επιλύθηκε") {
      const res = await addDoc(collection(db, "mail"), {
        to: requests[0].email,
        message: {
          subject: "enametonpoliti.gr",
          text: "Το αίτημα σας στο enametonpoliti.gr επιλύθηκε. Σας ευχαριστούμε.",
          html: "",
        },
      });
    }
  };

  const handleDecline = async (id) => {
    const docRef = doc(db, "requests", id);
    await deleteDoc(docRef);
    setOpen(false);
    toast.success("Διαγράφηκε", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
      hideProgressBar: true,
    });
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <Sidebar />
      <div className="flex flex-col flex-1 w-full">
        <Header />
        <main className="h-full overflow-y-auto">
          <div className="container px-6 mx-auto grid">
            <div className="flex items-center justify-between my-8 p-4 text-md bg-white font-semibold rounded-lg shadow-md focus:outline-none">
              <div className="flex items-center">
                <Link
                  to="/"
                  className="px-3 mr-6 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                >
                  Ολα τα αιτήματα
                </Link>
                <div className="left-1.5 z-20 bg-green-100 py-1 px-3">{hex(id).slice(0, 8)}</div>
              </div>
              <div className="flex items-center justify-between">
                {requests[0].status === "Νέο αίτημα" ? (
                  <button
                    onClick={() => handleAccept(id)}
                    className="px-3 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                  >
                    Αποδοχή
                  </button>
                ) : (
                  ""
                )}
                <button
                  onClick={onOpenModal}
                  className="flex items-center justify-between ml-3 pl-2 px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                  aria-label="Delete"
                >
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
                <Modal open={open} onClose={onCloseModal} center classNames="">
                  <h2 className="mb-8">
                    Με την συγκεκριμένη ενέργεια το αίτημα θα διαγραφεί μόνιμα.
                  </h2>
                  <button
                    onClick={() => handleDecline(id)}
                    className="bg-red mr-5 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                  >
                    ΜΟΝΙΜΗ ΔΙΑΓΡΑΦΗ
                  </button>
                  <button
                    onClick={onCloseModal}
                    className="bg-green px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                  >
                    ΔΙΑΤΗΡΗΣΗ ΑΙΤΗΜΑΤΟΣ
                  </button>
                </Modal>
              </div>
            </div>
            <div className="grid gap-6 mb-8 md:grid-cols-2">
              <div className="min-w-0">
                <div className="relative flex mt-4 px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <img
                    className="object-cover h-48 w-100 shadow-mb rounded-md"
                    src={
                      requests[0].photoURL ? requests[0].photoURL : placeholder
                    }
                  />
                </div>
                <div className="map px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <Map lat={lat} lng={lng} />
                </div>
              </div>
              <div className="min-w-0">
                <h4 className="my-6 mb-4 text-lg font-semibold text-gray-600 dark:text-gray-300">
                  Προέλευση αιτήματος
                </h4>
                <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <label className="block text-sm">
                    <span className="text-gray-700 dark:text-gray-400 capitalize">
                      Όνομα
                    </span>
                    <input
                      disabled
                      className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                      placeholder={requests[0].fullname}
                    />
                  </label>
                  <label className="block text-sm mt-4">
                    <span className="text-gray-700 dark:text-gray-400 capitalize">
                      Ηλεκτρονικό ταχυδρομείο
                    </span>
                    <input
                      disabled
                      className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                      placeholder={requests[0].email}
                    />
                  </label>
                  <label className="block text-sm mt-4">
                    <span className="text-gray-700 dark:text-gray-400 capitalize">
                      Τηλέφωνο
                    </span>
                    <input
                      disabled
                      className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                      placeholder={requests[0].phone}
                    />
                  </label>
                </div>
                <h4 className="mb-4 text-lg font-semibold text-gray-600 dark:text-gray-300">
                  Τίτλος αιτήματος
                </h4>
                <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <form onSubmit={handleEditStatus}>
                    <label className="block text-sm mt-4">
                      <span className="text-gray-700 dark:text-gray-400 capitalize">
                        Τίτλος
                      </span>
                      <input
                        disabled
                        className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                        placeholder={requests[0].title}
                      />
                    </label>
                    <label className="block text-sm mt-4">
                      <span className="text-gray-700 dark:text-gray-400 capitalize">
                        Ημερομηνία
                      </span>
                      <input
                        disabled
                        className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                        placeholder={requests[0].date}
                      />
                    </label>
                    <label className="block text-sm mt-4">
                      <span className="text-gray-700 dark:text-gray-400 capitalize">
                        Διεύθυνση
                      </span>
                      <input
                        disabled
                        className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                        placeholder={requests[0].address}
                      />
                    </label>
                    <label className="block mt-4 text-sm">
                      <span className="text-gray-700 dark:text-gray-400 capitalize">
                        Περιγραφή
                      </span>
                      <textarea
                        disabled
                        className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-textarea focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                        rows="8"
                        placeholder={requests[0].description}
                      ></textarea>
                    </label>
                    {requests[0].status !== "Νέο αίτημα" ? (
                      <>
                        <label className="block mt-4 text-sm">
                          <span className="text-gray-700 dark:text-gray-400 capitalize">
                            Κατάσταση
                          </span>
                          <select
                            value={state.value}
                            onChange={handleChange}
                            className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                          >
                            <option defaultValue disabled>
                              {requests[0].status}
                            </option>
                            <option>Νέο αίτημα</option>
                            <option>Καταχωρήθηκε</option>
                            <option>Γνωστοποιήθηκε</option>
                            <option>Σε εξέλιξη</option>
                            <option>Επιλύθηκε</option>
                          </select>
                        </label>
                        <button className="mt-4 px-3 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple">
                          Ανανέωση
                        </button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </form>
                </div>
                {requests[0].status === "Γνωστοποιήθηκε" ? (
                  <div>
                    <RequestSend
                      id={requests[0].id}
                      fullname={requests[0].fullname}
                      email={requests[0].email}
                      phone={requests[0].phone}
                      date={requests[0].date}
                      title={requests[0].title}
                      address={requests[0].address}
                      description={requests[0].description}
                      photoURL={
                        requests[0].photoURL === ""
                          ? null
                          : requests[0].photoURL
                      }
                      department={requests[0].department}
                      departmentPhone={requests[0].departmentPhone}
                      departmentEmail={requests[0].departmentEmail}
                      serviceManager={requests[0].serviceManager}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SingleRequest;
