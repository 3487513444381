import React, { useState } from "react";
import { ReactDOM } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useUserAuth } from "./../context/userAuthContext";

const Header = ({ onChange }) => {
  const navigate = useNavigate();
  let { user, logOut } = useUserAuth();

  const handleLogOut = async () => {
    try {
      await logOut();
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <header className="z-10 py-4 w-full bg-white dark:bg-gray-800">
        <div className="container flex items-center justify-end px-6 mx-auto text-purple-600 dark:text-purple-300">
          <button
            className="p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:shadow-outline-purple"
            aria-label="Menu"
          >
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>

          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="relative">
              {user ? (
                <button
                  onClick={handleLogOut}
                  className="ml-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent  active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                >
                  {!user ? "Σύνδεση" : "Αποσύνδεση"}
                </button>
              ) : (
                <button
                  onClick={() => navigate("/login")}
                  className="ml-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent  active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                >
                  Σύνδεση
                </button>
              )}
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

export default Header;
