import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom'

import ProtectedRoute from './components/ProjectedRoute'
import { UserAuthContextProvider } from './context/userAuthContext'

//components
import Header from './components/Header'
import Sidebar from "./components/Sidebar";

//pages
import Login from './pages/Login'
import Requests from './pages/Requests'
import SignleRequests from './pages/SingleRequest'
import Services from './pages/Services'
import NotFoundPage from './pages/NotFoundPage'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  localStorage.theme = 'light'
  
  return (
    <BrowserRouter>
      <UserAuthContextProvider>
        <ToastContainer />
        <Routes>
          <Route exact path="/" element={<ProtectedRoute><Requests /></ProtectedRoute> } />
          <Route path="/requests" element={<ProtectedRoute><Requests /></ProtectedRoute> } />
          <Route path="/single-request" element={<ProtectedRoute><SignleRequests /></ProtectedRoute> } />
          <Route path="/services" element={<ProtectedRoute><Services /></ProtectedRoute> } />
          <Route path="/login" element={<Login /> } />
          <Route path="*" element={<NotFoundPage /> } />
        </Routes>  
      </UserAuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
