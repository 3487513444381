import React, { useEffect, useState } from "react";
import { onSnapshot, collection, query, orderBy } from "firebase/firestore";
import { db } from "../config/firebase";
import RequestsListSingle from "./../components/RequestsListSingle";

const RequestsList = ({ search }) => {
  const [requests, setRequests] = React.useState([]);

  useEffect(() => {
    const q = query(collection(db, "requests"), orderBy("date", "desc"));
    onSnapshot(q, collection(db, "requests"), (snapshot) =>
      setRequests(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );
  }, []);

  const filterRequests = requests.filter((req) => {
    return (req.title.toLowerCase() + req.status.toLowerCase()).includes(
      search.toLowerCase()
    );
  });

  return (
    <div className="w-full overflow-x-auto  shadow-md px-4 py-3 mb-8 bg-white">
      <table className="w-full whitespace-no-wrap">
        <thead>
          <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
            <th className="px-4 py-3">ID</th>
            <th className="px-4 py-3">Ημερομηνία</th>
            <th className="px-4 py-3">Τίτλος</th>
            <th className="px-4 py-3">Αρμόδια υπηρεσία</th>
            <th className="px-4 py-3">Κατάσταση</th>
            <th className="px-4 py-3">Επεξεργασία</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
          {filterRequests.map((key) =>
            key.position.map((pos) => (
              <RequestsListSingle
                key={key.id}
                id={key.id}
                email={key.email}
                fullname={key.fullname}
                phone={key.phone}
                date={key.date}
                title={key.title}
                address={key.address}
                description={key.description}
                status={key.status}
                photoURL={key.photoURL}
                lat={pos.lat}
                lng={pos.lng}
                department={key.department}
                serviceManager={key.serviceManager}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RequestsList;
