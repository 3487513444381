import React from "react";
import { ReactDOM } from "react-dom";
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import logo from "../assets/img/logo-main.png";

const Sidebar = () => {
  return (
    <>
      <aside className="z-20 hidden w-64 overflow-y-auto bg-purple-600 dark:bg-gray-800 md:block flex-shrink-0">
        <div className="mb-4 text-gray-500 dark:text-gray-400">
          <Link to="/">
            <img className="p-4 w-9" src={logo} />
          </Link>
          <ul className="mt-6">
            <li className="relative px-6 py-3">
              <div className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 text-white dark:hover:text-gray-200">
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
                </svg>
                <Link to="/requests">
                  <span className="ml-4">Αιτήματα</span>
                </Link>
              </div>
            </li>
          </ul>
          <ul>
            <li className="relative px-6 py-3">
              <div className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 text-white dark:hover:text-gray-200">
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 10h16M4 14h16M4 18h16"></path>
                </svg>
                <Link to="/services">
                  <span className="ml-4">Υπηρεσίες</span>
                </Link>
              </div>
            </li>
            <li className="relative px-6 py-3">
              <div className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 text-white dark:hover:text-gray-200">
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  stroke="currentColor"
                >
                  <path d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
                </svg>
                <Link to="https://enametonpoliti.gr/">
                  <span className="ml-4">Δημόσια Προβολή</span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </aside>
      <aside className="fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 md:hidden">
        <div className="py-4 text-gray-500 dark:text-gray-400">
          <Link
            to="/"
            className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
          >
            Dashboard
          </Link>
          <ul className="mt-6">
            <li className="relative px-6 py-3">
              <div className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                <svg
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
                </svg>
                <Link to="/requests">
                  <span className="ml-4">Αιτήματα</span>
                </Link>
              </div>
            </li>
            <li className="relative px-6 py-3">
              <button
                className="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                aria-haspopup="true"
              >
                <span className="inline-flex items-center">
                  <svg
                    aria-hidden="true"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path d="M4 6h16M4 10h16M4 14h16M4 18h16"></path>
                  </svg>
                  <Link to="/services">
                    <span className="ml-4">Υπηρεσίες</span>
                  </Link>
                </span>
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <template x-if="isPagesMenuOpen">
                <ul
                  className="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900"
                  aria-label="submenu"
                >
                  <li className="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <a className="w-full" href="#">
                      Προβολή όλες{" "}
                    </a>
                  </li>
                  <li className="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <a className="w-full" href="#">
                      Προσθήκη{" "}
                    </a>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
          <ul>
            <li className="relative px-6 py-3">
              <div className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                <svg
                  aria-hidden="true"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
                </svg>
                <Link to="https://google.com">
                  <span className="ml-4">Δημόσια Προβολή</span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
