import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./../components/Header";
import Sidebar from "./../components/Sidebar";
import RequestsList from "./../components/RequestsList";

const Requests = () => {
  const [search, setSearch] = React.useState("");

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <Sidebar />
      <div className="flex flex-col flex-1 w-full">
        <Header />
        <main className="h-full overflow-y-auto">
          <div className="container px-6 mx-auto grid">
            <h2 className="w-full text-2xl text-gray-700 dark:text-gray-200 flex items-center font-semibold justify-between my-8 p-4 mb-4 bg-white   shadow-md focus:outline-none">
              Αιτήματα
              <div className="flex justify-between items-center">
                <svg
                  className="w-8 h-8 mr-4 mt-1 -ml-1 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7.75 4H19M7.75 4a2.25 2.25 0 0 1-4.5 0m4.5 0a2.25 2.25 0 0 0-4.5 0M1 4h2.25m13.5 6H19m-2.25 0a2.25 2.25 0 0 1-4.5 0m4.5 0a2.25 2.25 0 0 0-4.5 0M1 10h11.25m-4.5 6H19M7.75 16a2.25 2.25 0 0 1-4.5 0m4.5 0a2.25 2.25 0 0 0-4.5 0M1 16h2.25"
                  />
                </svg>
                <select
                  onChange={(e) => setSearch(e.target.value)}
                  className="block mr-5 mt-1 text-sm font-light dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                >
                  <option value={""}>Προβολή όλων</option>
                  <option>Νέο αίτημα</option>
                  <option>Καταχωρήθηκε</option>
                  <option>Γνωστοποιήθηκε</option>
                  <option>Σε εξέλιξη</option>
                  <option>Επιλύθηκε</option>
                </select>
                <div className="relative w-full max-w-xl focus-within:text-purple-500">
                  <div className="absolute inset-y-0 flex items-center pl-2">
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      fill="#707275"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    onChange={(e) => setSearch(e.target.value)}
                    className="font-light w-full pl-8 pr-2 text-sm text-gray-700 placeholder-gray-100 bg-gray-100 border-0 rounded-md dark:placeholder-gray-500 dark:focus:shadow-outline-gray dark:focus:placeholder-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:placeholder-gray-500 focus:bg-white focus:border-purple-300 focus:outline-none focus:shadow-outline-purple form-input"
                    type="text"
                    placeholder="Αναζήτηση"
                    aria-label="Search"
                  />
                </div>
              </div>
            </h2>
            <RequestsList search={search} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Requests;
