import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import {
  collection,
  getDoc,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RequestSend = ({
  id,
  fullname,
  email,
  phone,
  date,
  title,
  address,
  description,
  photoURL,
  department,
  departmentEmail,
  departmentPhone,
  serviceManager,
}) => {
  const [services, setServices] = React.useState([]);
  const [mail, setMail] = React.useState({
    to: "",
    message: {
      subject: "",
      text: "",
      html: "",
    },
  });

  const hex = require("string-hex");

  useEffect(
    () =>
      onSnapshot(collection(db, "departments"), (snapshot) =>
        setServices(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );

  const [state, setState] = React.useState({
    value: "Εσωτερικό",
  });

  const handleChange = (e) => {
    setState({ value: e.target.value });
  };

  const handleSend = async (e) => {
    e.preventDefault();

    let department, serviceManager, departmentEmail, departmentPhone;
    for (let i = 0; i < services.length; i++) {
      if (services[i].id == state.value) {
        department = services[i].department;
        serviceManager = services[i].serviceManager;
        departmentEmail = services[i].departmentEmail;
        departmentPhone = services[i].departmentPhone;
        break;
      }
    }

    const docRef = doc(db, "requests", id);
    await updateDoc(docRef, {
      department: department,
      serviceManager: serviceManager,
      departmentPhone: departmentPhone,
      departmentEmail: departmentEmail,
    });

    try {
      const res = await addDoc(collection(db, "mail"), {
        to: departmentEmail,
        message: {
          subject: "enametonpoliti.gr",
          text: "",
          html:
            "<p>Καταχωρήθηκε αίτημα στην υπηρεσία της Περιφέρεια Ανατολικής Μακεδονίας Θράκης enametonpoliti.gr το οποίο προωθούμε στην υπηρεσία/φορέα σας προς επίλυση.</p>" +
            "<p><strong>ID: </strong>" +
            hex(id).slice(0, 8) +
            "</p>" +
            "<p><strong>Όνοματεπώνυμο: </strong>" +
            fullname +
            "</p>" +
            "<p><strong>Email: </strong>" +
            email +
            "</p>" +
            "<p><strong>Τηλέφωνο: </strong>" +
            phone +
            "</p><br/>" +
            "<p><strong>Τίτλος </strong>" +
            title +
            "</p>" +
            "<p><strong>Διευθυνση </strong>" +
            address +
            "</p>" +
            "<p><strong>Περιγραφή </strong>" +
            description +
            "</p>" +
            "<p><strong>Ημερομηνία </strong>" +
            date +
            "</p><br/>" +
            "<img src=" +
            photoURL +
            "/>" +
            "<img src='https://firebasestorage.googleapis.com/v0/b/enametonpoliti-4c958.appspot.com/o/logo_perifereias.png?alt=media&token=f567b315-a421-4098-be7a-5ca0f0ccd396' alt='logo' />",
        },
      });
      toast.success("Το μήνυμα στάλθηκε", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
      });
      const res2 = await addDoc(collection(db, "mail"), {
        to: email,
        message: {
          subject: "enametonpoliti.gr",
          text: "",
          html:
            "<p>Το enametonpoliti.gr γνωστοποίησε το αίτημα σας στην παρακάτω υπηρεσία/φορέα ο οποίος είναι υπεύθυνος για την επίλυση του. Σας ευχαριστούμε.</p>" +
            "<p><strong>Όνομα υπηρεσίας/φορέα: </strong>" +
            department +
            "</p>" +
            "<p><strong>Email υπηρεσίας/φορέα:  </strong>" +
            departmentEmail +
            "</p>" +
            "<p><strong>Τηλέφωνο υπηρεσίας/φορέα: </strong>" +
            departmentPhone +
            "</p>" +
            "<p><strong>Υπεύθυνος υπηρεσίας/φορέα: </strong>" +
            serviceManager +
            "</p>" +
            "<img src='https://firebasestorage.googleapis.com/v0/b/enametonpoliti-4c958.appspot.com/o/logo_perifereias.png?alt=media&token=f567b315-a421-4098-be7a-5ca0f0ccd396' alt='logo' />",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <h4 className="mb-4 text-lg font-semibold text-gray-600 dark:text-gray-300">
        Αποστολή
      </h4>
      <div className="px-4 py-3 mb-8 bg-white  shadow-md dark:bg-gray-800">
        <form onSubmit={handleSend}>
          <label className="block mt-4 text-sm">
            <select
              onChange={handleChange}
              className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
            >
              <option defaultValue disabled>
                {state.value}
              </option>
              <option defaultValue>Εσωτερικό</option>
              {services.map((key) => (
                <option key={key.id} value={key.id}>
                  {key.department}
                </option>
              ))}
            </select>
          </label>
          {state.value !== "Εσωτερικό" ? (
            <button className="mt-4 px-3 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple">
              Αποστολή
            </button>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default RequestSend;
