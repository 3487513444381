import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./../components/Header";
import Sidebar from "./../components/Sidebar";
import ServicesList from "./../components/ServicesList";
import ServiceAdd from "./../components/ServiceAdd";
import img from "./../assets/img/create-account-office.jpeg";

const Services = () => {
  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <Sidebar />
      <div className="flex flex-col flex-1 w-full">
        <Header />
        <main className="h-full overflow-y-auto">
          <div className="container px-6 mx-auto grid">
            <h2 className="w-full text-2xl  text-gray-700 dark:text-gray-200 flex items-center justify-between my-8 p-4 mb-4 bg-white font-semibold  shadow-md focus:outline-none">
              Υπηρεσίες
            </h2>
            <div className="w-full grid gap-6 mb-8 md:grid-cols-2 my-8mb-4 bg-white  shadow-md focus:outline-none">
              <div className="min-w-0 p-4">
                <ServiceAdd />
              </div>
              <div className="min-w-0 p-4">
                <img
                  className="object-cover h-48 w-100 shadow-mb rounded-md"
                  src={img}
                />
              </div>
            </div>
            <div className="w-full my-8 mb-4 text-md bg-white  shadow-md focus:outline-none">
              <ServicesList />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Services;
